import React from 'react';
import '../App.css';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <h1>Habit Coach AI</h1>
      </div>
      <div className="navbar-buttons">
        <button className="nav-button">Login</button>
        <button className="nav-button signup">Sign Up</button>
      </div>
    </nav>
  );
};

export default Navbar;
