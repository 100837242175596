import React from 'react';
import '../App.css';  // Import the CSS

// Import all images
import calendarImg from '../images/calendar.png';
import callImg from '../images/call.png';
import goalsImg from '../images/goals.png';
import messagesImg from '../images/messages.png';
import analyticsImg from '../images/analytics.png';

const HomePage = () => {
  return (
    <div>
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1 className="hero-title">
            Build Better Habits with Your AI Coach
          </h1>
          <p className="hero-text">
            Your personal AI accountability partner helps you stay on track and achieve your goals through daily conversations and scheduled check-ins.
          </p>
          <button className="cta-button">
            Get Started
          </button>
        </div>
        <div className="hero-image">
          <img src={calendarImg} alt="Calendar View" className="phone-screenshot floating" />
        </div>
      </section>

      {/* Features Grid */}
      <section className="features-section">
        <div className="feature-grid">
          <div className="feature-text">
            <h2>AI Voice Calls</h2>
            <p>Have natural voice conversations with your AI coach that feel just like talking to a real accountability partner.</p>
          </div>
          <div className="feature-image">
            <img src={callImg} alt="AI Voice Calls" className="phone-screenshot" />
          </div>

          <div className="feature-image">
            <img src={goalsImg} alt="Goal Setting" className="phone-screenshot" />
          </div>
          <div className="feature-text">
            <h2>Goal Setting & Tracking</h2>
            <p>Set meaningful goals and track your progress with visual insights and regular updates.</p>
          </div>

          <div className="feature-text">
            <h2>Chat Anytime</h2>
            <p>Message your AI coach whenever you need support, guidance, or just want to discuss your progress.</p>
          </div>
          <div className="feature-image">
            <img src={messagesImg} alt="Chat Messages" className="phone-screenshot" />
          </div>

          <div className="feature-image">
            <img src={analyticsImg} alt="Analytics Dashboard" className="phone-screenshot" />
          </div>
          <div className="feature-text">
            <h2>Progress Analytics</h2>
            <p>Get detailed insights into your habit formation and goal achievement with comprehensive analytics.</p>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="cta-section">
        <h2>Ready to Transform Your Habits?</h2>
        <p>Join thousands of users building better habits with AI coaching.</p>
        <button className="cta-button">Download Now</button>
      </section>
    </div>
  );
};

export default HomePage;
